import { useAxios  } from "./../lib/AxiosProvider"
const useUpload = () => {
    const axios  = useAxios();

    
    async function totalStudentReq(){
        const { data } = await axios.get('total-student');
        return data;
    }
    async function totalSubjectReq(){
        const { data } = await axios.get('total-subject');
        return data;
    }

    async function totalQuestionsReq(){
        const { data } = await axios.get('total-questions');
        return data;
    }
    async function totalBookssReq(){
        const { data } = await axios.get('total-books');
        return data;
    }
    async function totalExamsReq(){
        const { data } = await axios.get('total-exam-taken');
        return data;
    }
    async function subscriberCount(){
        const { data } = await axios.get('total-subscriber-count');
        return data;
    }
    async function examInDraftReq(){
        const { data } = await axios.get('total-exam-in-draft');
        return data;
    }
    
    async function toDaysVisitorReq(){
        const { data } = await axios.get('visitor-today');
        return data;
    }
    
    async function AllVisitorReq(){
        const { data } = await axios.get('visitor-all');
        return data;
    }
    
    async function LastOneWeekVisitorReq(){
        const { data } = await axios.get('visitor-last-one-week');
        return data;
    }
    

    return {
        totalStudentReq,
        totalSubjectReq,
        totalQuestionsReq,
        totalBookssReq,
        totalExamsReq,
        subscriberCount,
        examInDraftReq,
        toDaysVisitorReq,
        AllVisitorReq,
        LastOneWeekVisitorReq
    }
}

export default useUpload
