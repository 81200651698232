import { useState } from 'react'
import { useAxios  } from "./../../lib/AxiosProvider"
const useAuth = () => {
    const axios = useAxios();
    const [user, setUser] = useState(null);

    async function loginRequest(payload){
        const { data  } = await axios.post('/admin/login', payload);
        return data;
    }

    async function validateUser(payload){
        const { data  } = await axios.post('/admin/validate', payload);
        return data;
    }


     async function addModeratorReq(payload) {
       const { data } = await axios.post("/admin/add-moderator", payload);
       return data;
     }

     async function getModeratorlistReq(payload) {
       const { data } = await axios.get("/admin/list-moderator", payload);
       return data;
     }

     async function deleteModeratorReq(payload) {
       const { data } = await axios.post("/admin/delete-moderator", payload);
       return data;
     }

  async function userListReq(payload) {
    const { data } = await axios.get("/admin/user-list", payload);
    return data;
  }

  async function changePlanReq(payload) {
    const { data } = await axios.post("/admin/change-user-plan", payload);
    return data;
  }


  async function fetchAllTransectionReq(payload) {
    const { data } = await axios.get("/admin/fetch-all-transection", payload);
    return data;
  }

  async function changeTransectionStatus(payload) {
    const { data } = await axios.post(
      "/admin/change-transection-status",
      payload
    );
    return data;
  }

  return {
    loginRequest,
    validateUser,
    user,
    setUser,
    addModeratorReq,
    getModeratorlistReq,
    deleteModeratorReq,
    userListReq,
    changePlanReq,
    fetchAllTransectionReq,
    changeTransectionStatus,
  };
}

export default useAuth
