import React, { useState, useEffect } from 'react';

import {Table, Button ,Card, message, Tag, Popconfirm, Input } from "antd";
import Icon from "@ant-design/icons"
import useLibrary from '../../hooks/useLibrary';
import { useQuery, useMutation , useQueryClient} from "react-query";

const List = ({ setView, setUpdate }) => {

  const { BookFetchRequest, BookDeleteReq } = useLibrary();

  const [text, setText] = useState("");
  const [page, setPage] = useState(1);
  const [totalBook, setTotalBook] = useState(0);
  const { data:libraryData, isLoading } = useQuery(['books',page, text], () => BookFetchRequest({ text, page}));

  
  const queryClient = useQueryClient();
  const [books, setBooks] = useState([]);


  const { mutate:deleteAction } = useMutation(BookDeleteReq, {
      onSuccess:res => {
        message.success("Operation successfull");
        queryClient.invalidateQueries(['books',page, text])
        },
      onError:err => {
        message.error(err.response.data.message)
      }
  });



  useEffect(() => {
    if(libraryData !== undefined){
      setBooks(libraryData.books);
      setTotalBook(libraryData.count);
    }
  }, [libraryData]);
  
  
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title) => title,
    },

    {
      title: "Writer",
      dataIndex: "writer",
      key: "writer",
    },
    {
      title: "Cover",
      dataIndex: "cover",
      key: "cover",
      render: (cover) => <img width={40} height={50} src={cover} alt='' />,
    },
    {
      title: "Downloaded",
      dataIndex: "downloadCount",
      key: "downloadCount",
      render: (downloadCount) => <Tag>{downloadCount}</Tag>,
    },
    {
      title: "*",
      dataIndex: "_id",
      key: "_id",
      render: (id, row) => (
        <>
          <a href={row.url} target='_blank' rel='noopener noreferrer'>
            <Button size='small'>
              <Icon type='download' />
            </Button>
          </a>

          <Button
            size='small'
            onClick={() => {
              setView("add");
              setUpdate({
                ...row,
              });
            }}
          >
            Update
          </Button>
          <Popconfirm
            title='are you sure to Delete ?'
            onConfirm={() => deleteAction({ id })}
          >
            <Button size='small'>Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ];



   //setup before functions
	let typingTimer;                //timer identifier
	let doneTypingInterval = 700;  //time in ms (1 seconds)
	function doneTyping (ev) {
		setText(ev);
	}
  
  function handleSearch(e){
    const { value } = e.target
    if(value===""){
      setText("");
    }
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => doneTyping(value), doneTypingInterval); 
  }

  return <>
    <Card title="Book List" extra={[
        <Input size='small' key="search" name='text' onChange={handleSearch} placeholder='Search' style={{ width:150 }} />,
        <Button size='small' key="add-btn" onClick={() => {  
          setView('add');
        }}>Add Book</Button>]
      }>
              <Table loading={isLoading} dataSource={books} rowKey="_id" size="small" columns={columns} pagination={{
                total:totalBook,
                onChange:e => {
                  setPage(e);
                },
                showSizeChanger:false
              }} />;
      </Card>
  </>;
};

export default List;
