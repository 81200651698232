import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Spin } from "antd"
import '../src/styles/global.scss';
import { AxiosProvider } from "./lib/AxiosProvider"
import './index.scss';
import ProtectedRoute from "./lib/Protected"
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Login from './pages/login';
import Register from './pages/register';
import Dashboard from './pages/dashboard';
import NoMatchPage from "./pages/NotFoundPage"
import {Route,  Switch, BrowserRouter as Router} from 'react-router-dom';

import Library from "./pages/library"
import { useState } from "react";
import { useEffect } from "react";

const Category = lazy(() => import("./pages/post/category"));
const Posts = lazy(() => import("./pages/post"));

const ExamCategories = lazy(() => import("./pages/exam/exam-categories"));
const McqExam = lazy(() => import("./pages/exam/msq"));
const AddMcqExam = lazy(() => import("./pages/exam/msq/Add"));
const UpdateMcqExam = lazy(() => import("./pages/exam/msq/update"));
const GeneratedQuestion = lazy(() => import("./pages/exam/generated"));

const Learn = lazy(() => import("./pages/learn"));
// const Package = lazy(() => import("./pages/package"));
const Moderator = lazy(() => import("./pages/moderator"));
const UserList = lazy(() => import("./pages/user-list"));
const Payments = lazy(() => import("./pages/payments"));

const queryClient = new QueryClient();

queryClient.setDefaultOptions({
  queries: {
    refetchOnWindowFocus: false,
  },
});

const Routing = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userFromStorage = localStorage.getItem("user");
    if (userFromStorage) {
      setUser(JSON.parse(userFromStorage));
    }
  }, []);

  const buildProps = {
    ...props,
    user,
    setUser,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <>
            <Spin />
          </>
        }
      >
        <Router>
          <Switch>
            <Route exact path='/'>
              <Login {...buildProps} />
            </Route>
            <Route exact path='/register' component={Register} />
            <ProtectedRoute exact path='/dashboard' component={Dashboard} />
            {/* // post  */}
            <ProtectedRoute exact path='/post-category' component={Category} />
            <ProtectedRoute exact path='/posts' component={Posts} />
            {/* // Exams  */}
            <ProtectedRoute
              exact
              path='/exam-categories'
              component={ExamCategories}
            />
            <ProtectedRoute exact path='/mcq' component={McqExam} />
            <ProtectedRoute exact path='/mcq-add' component={AddMcqExam} />
            <ProtectedRoute
              exact
              path='/mcq-update/:id'
              component={UpdateMcqExam}
            />
            <ProtectedRoute
              exact
              path='/generated-questions'
              component={GeneratedQuestion}
            />
            <ProtectedRoute exact path='/library' component={Library} />
            <ProtectedRoute exact path='/learn' component={Learn} />
            {/* <ProtectedRoute exact path='/package' component={Package} /> */}
            <ProtectedRoute exact path='/moderator' component={Moderator} />
            <ProtectedRoute exact path='/user-list' component={UserList} />
            <ProtectedRoute exact path='/payments' component={Payments} />

            <Route path='*' component={NoMatchPage} />
          </Switch>
          <ReactQueryDevtools initialIsOpen={false} />
        </Router>
      </Suspense>
    </QueryClientProvider>
  );
};



// Render 
const container = document.getElementById("root")
const root = createRoot(container);


const RootComponent = () => (
  <AxiosProvider>
  <Routing />
</AxiosProvider>
);

root.render(<RootComponent />)