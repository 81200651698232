import React, { createContext, useMemo, useContext } from "react";
import Axios from "axios";
const AxiosContext = createContext();

const devBaseUrl = "http://localhost:5000/estudy/";
const productionBaseUrl = "https://api.preadmissionbd.com/estudy/";

export function AxiosProvider({ children }) {
	const axios = useMemo(() => {
		const axios = Axios.create({
			headers: {
				"Content-Type": "application/json",
			},
		});

		axios.interceptors.request.use((config) => {
			// Read token for anywhere, in this case directly from localStorage
			if (String(window.location.href).indexOf("localhost") > -1) {
				config.baseURL = devBaseUrl;
			} else {
				config.baseURL = productionBaseUrl;
			}

			const token = localStorage.getItem("__token");
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		});

		return axios;
	}, []);

	return (
		<AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
	);
}

export function useAxios() {
	return useContext(AxiosContext);
}

export function useBaseUrl() {
	var url =
		String(window.location.href).indexOf("localhost") > -1
			? devBaseUrl
			: productionBaseUrl;

	return {
		url,
	};
}
