import React, { useState,  useRef, useEffect } from 'react';
import { Card, Input, Form, Row, Col, Button, message } from "antd";
import { useMutation } from "react-query";
import useUpload from '../../hooks/useUpload';
import useLibrary from '../../hooks/useLibrary';
const List = ({ setView, setUpdate, update }) => {
  const {  uploadRequest } = useUpload();
  const { addBookReq, updateBookReq } = useLibrary();
  const [state, setState] = useState({
    title:"",
    cover:"",
    writer:"",
    url:""
  });


  useEffect(() => {
    if(update !==undefined && update !== null){
      setState(update)
    }
  }, [update]);


  const fileUpload = useRef()
  function inputHandler(event){
    const { name, value } = event.target;
    setState(p => ({...p, [name]:value}));
  }

  const { mutate:uploadAction , isLoading:uploading} = useMutation(uploadRequest, {
    onSuccess:res => {
      setState(p => ({...p, cover:res.url}))
    },
    onError:err => {
      console.log(err)
    }
  })

  const { mutate:addBookAction, isLoading } = useMutation(addBookReq, {
      onSuccess:res => {
        message.success("Operation successfull");
      },
      onError:err => {
        message.error(err.response.data.message);
      }
  })

  const { mutate:updateBookAction, isLoading:updateLoading } = useMutation(updateBookReq, {
      onSuccess:res => {
        message.success("Update successfull");
      },
      onError:err => {
        message.error(err.response.data.message);
      }
  })


  async function uploadImage(event) {
    const file = event.target.files[0]
		const formData = new FormData();
		formData.append("file",file);
    uploadAction(formData)
	}


  function submitHandler(){
    if(state.title===""){
      message.error('Type Title')
    }else if(state.url===""){
      message.error("Download Url ?")
    }else if(state.writer===""){
      message.error('Book Writer name ?')
    }else if(state.cover===""){
      message.error('upload cover image')
    }else{
      if(update===null){
        addBookAction(state)
      }else{
        updateBookAction(state)
      }
      
    }
  }


  return (
    <Card
      title={update === null ? "Add Book" : "Update Book"}
      extra={[
        <Button
          onClick={(_) => {
            setView("");
            setUpdate(null);
          }}
          key='back-btn'
          size='small'
        >
          Back
        </Button>,
      ]}
    >
      <Row>
        <Col sm={24} md={24} lg={12}>
          <Card>
            <Form>
              <Form.Item label='Title'>
                <Input
                  value={state.title}
                  onChange={inputHandler}
                  name='title'
                  size='small'
                />
              </Form.Item>

              <Form.Item
                label={`Cover Photo ${uploading ? "Uploading..." : ""}`}
              >
                <button
                  onClick={() => fileUpload.current.click()}
                  className='upload-photo-btn'
                >
                  upload photo
                </button>
                <input
                  onChange={uploadImage}
                  style={{ display: "none" }}
                  type='file'
                  ref={fileUpload}
                />
              </Form.Item>

              <Form.Item label='Writer'>
                <Input
                  onChange={inputHandler}
                  value={state.writer}
                  name='writer'
                  size='small'
                  placeholder='Cover photo'
                />
              </Form.Item>

              <Form.Item label='Download Link'>
                <Input
                  size='small'
                  value={state.url}
                  onChange={inputHandler}
                  name='url'
                  placeholder='Download Link'
                />
              </Form.Item>

              <Form.Item>
                <Button
                  size='small'
                  onClick={submitHandler}
                  loading={isLoading || updateLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Col sm={24} md={24} lg={12}>
          <Card>
            <div className='center pb-5'>
              <span>Preview</span>
            </div>
            <div className='flex-center'>
              <div
                style={{
                  backgroundImage: `url(${
                    state.cover !== "" ? state.cover : ""
                  })`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                className='book-preview'
              >
                <div className='book-details'>
                  <span className='book-title'>{state.title}</span>
                  <span className='book-writer'>{state.writer}</span>
                </div>
                <a
                  href={state.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='download-btn'
                >
                  Download{" "}
                </a>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default List;
