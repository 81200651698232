import React from 'react';
import { Link } from 'react-router-dom';
import HeaderDiv from './HeaderDiv';
import { Button, Layout, Menu } from 'antd';
// import Icon from "@ant-design/icons"
import { ArrowLeftOutlined, LayoutOutlined } from "@ant-design/icons"
const { SubMenu }  = Menu;
const { Header,Sider, Content } = Layout;


class Layouts extends React.Component {
  state = {
    collapsed: false,
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  rootSubmenuKeys = ["sub1", "sub2", "sub4", "sub5", "sub3", "sub6"];

  state = {
    openKeys: "",
    collapsed: false,
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  render() {
    const active = this.props.active;
    // const classname = (this.props, "classname", " ");

    const userData = localStorage.getItem("user");
    var user = null;
    if (userData) {
      user = JSON.parse(userData);
    }

    return (
      <Layout className={`${this.props.classname}`}>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          style={{ background: "#fff",marginTop:20 }}
          className='sidebar-left'
          onCollapse={this.onCollapse}
          
        >
          <div className='logo' />
          <Menu
            theme='light'
            mode='inline'
            defaultSelectedKeys={[active]}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
          >
            <Menu.Item key='1'>
              <Link to='/dashboard'>
                <div>
                  {/* <Icon type='dashboard' /> */}
                  <LayoutOutlined />
                  <span> Dashboard </span>
                </div>
              </Link>
            </Menu.Item>
            <SubMenu
              key='2'
              title={
                <span>
                  {/* <Icon type='layout' /> */}
                  <LayoutOutlined />
                  <span>Posts</span>
                </span>
              }
            >
              <Menu.Item key='sub2.2'>
                <Link to='/posts'>
                  <span>Posts</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='sub2.3'>
                <Link to='/post-category'>
                  <span>Categories</span>
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key='3'
              title={
                <span>
                  {/* <Icon type='layout' /> */}
                  <LayoutOutlined />
                  <span>Questions</span>
                </span>
              }
            >
              <Menu.Item key='sub2.1'>
                <Link to='/generated-questions'>
                  <span>Generated Question Paper</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='sub2.2'>
                <Link to='/mcq'>
                  <span>MCQ Questions</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='sub2.3'>
                <Link to='/exam-categories'>
                  <span>Question Categories</span>
                </Link>
              </Menu.Item>
            </SubMenu>

            {/* {user?.type === "admin" && (
              <Menu.Item key='4'>
                <Link to='/library'>
                  <div>
                    <Icon type='book' />
                    <span> Library </span>
                  </div>
                </Link>
              </Menu.Item>
            )} */}

            {/* {user?.type === "admin" && (
              <Menu.Item key='5'>
                <Link to='/learn'>
                  <div>
                    <Icon type='file' />
                    <span> Learn </span>
                  </div>
                </Link>
              </Menu.Item>
            )} */}

            {/* {user?.type === "admin" && (
              <Menu.Item key='6'>
                <Link to='/package'>
                  <div>
                    <Icon type='file' />
                    <span> Package </span>
                  </div>
                </Link>
              </Menu.Item>
            )} */}

            {user?.type === "admin" && (
              <Menu.Item key='moderator'>
                <Link to='/moderator'>
                  <div>
                    
                    {/* <Icon type='file' /> */}
                    <LayoutOutlined />
                    <span> Moderator </span>
                  </div>
                </Link>
              </Menu.Item>
            )}

            {user?.type === "admin" && (
              <Menu.Item key='user-list'>
                <Link to='/user-list'>
                  <div>
                    {/* <Icon type='file' /> */}
                    <LayoutOutlined />
                    <span> User List </span>
                  </div>
                </Link>
              </Menu.Item>
            )}

            {user?.type === "admin" && (
              <Menu.Item key='payments'>
                <Link to='/payments'>
                  <div>
                    {/* <Icon type='file' /> */}
                    <LayoutOutlined />
                    <span> Payments </span>
                  </div>
                </Link>
              </Menu.Item>
            )}
          </Menu>


          <Button style={{ width:'100%' }} onClick={this.toggle}>
            <ArrowLeftOutlined />
          </Button>

          {/* <Icon
            className='trigger'
            type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
            onClick={this.toggle}
          /> */}
        </Sider>
        <Layout>
          <Header className='headerTop'>
            <HeaderDiv />
            {/* <Icon
                className="trigger layout-trigger header-toggle"
                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={this.toggle}
                />  */}
          </Header>
          <Content
            style={{
              padding: 24,
              minHeight: "100vh",
            }}
            className={
              this.state.collapsed ? "collapsed mainContnet " : "mainContnet"
            }
          >
             
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}
export default Layouts;