import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LayoutLogin from '../components/LayoutLogin';
import {Form, Input, Button, message} from 'antd';
import Icon from "@ant-design/icons"
import { useMutation,useQueryClient } from "react-query";
import useAuth from '../hooks/auth/useAuth';
export default function Login({ setUser }) {
  const { loginRequest } = useAuth();
  const history = useHistory();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const queryClient = useQueryClient();
  function handleState(ev) {
    const { name, value } = ev.target;
    setState((p) => ({ ...p, [name]: value }));
  }

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      history.push("/dashboard");
    }
  }, [history]);

  const { mutate: loginAction, isLoading } = useMutation(loginRequest, {
    onSuccess: (payload) => {
      localStorage.setItem("user", JSON.stringify(payload));
      setUser(payload);
      localStorage.setItem("__token", payload.token);
      queryClient.setQueryData("user", payload);
      message.success("Login successfull");
      history.push("/dashboard");
    },
    onError: (err) => {
      message.error(err.response.data.message);
    },
  });

  function handleSubmit() {
    if (state.username === "") {
      message.error("type valid username");
    } else if (state.password === "") {
      message.error("type valid password");
    } else {
      loginAction(state);
    }
  }

  return (
    <LayoutLogin title='login' classname='login'>
      <div
        className='d-flex align-items-center justify-content-center flex-column'
        style={{ maxWidth: "360px", margin: "auto", height: "60vh" }}
      >
        <div className='text-center'>
          <h1 className='m-b-30 m-t-15'>e-study</h1>
        </div>
        <Form style={{ width: "100%" }}>
          <Form.Item label="Username">
            <Input
              value={state.username}
              name='username'
              onChange={handleState}
              // prefix={<Icon type='user' style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder='Username'
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input
              value={state.password}
              name='password'
              onChange={handleState}
              // prefix={<Icon type='lock' style={{ color: "rgba(0,0,0,.25)" }} />}
              type='password'
              placeholder='Password'
            />
          </Form.Item>
          <Form.Item>
            {/*               
              <Link className="float-right" to="">
                Forgot password
              </Link> */}

            <Button
              onClick={handleSubmit}
              type='primary'
              htmlType='submit'
              className='btn-block m-t-15'
              size='large'
              loading={isLoading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </LayoutLogin>
  );
}

