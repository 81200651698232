import { useAxios } from "./../lib/AxiosProvider"
const useUpload = () => {
    const axios  = useAxios();
   async function uploadRequest(formData){
        const { data } = await axios.post("/image-upload", formData); 
        return data;
    }

    return {
        uploadRequest
    }
}

export default useUpload
