import * as React from 'react';
import Layouts from '../components/Layouts';
import Stats from '../components/home/Stats';
import {Row, Col, Card} from 'antd';
import { useQuery } from "react-query"
import useDashboard from "./../hooks/useDashboard"
function Dashboard() {

  const { 
    totalStudentReq,
    totalSubjectReq,
    totalQuestionsReq,
    // totalBookssReq,
    totalExamsReq,
    // subscriberCount,
    examInDraftReq,
    toDaysVisitorReq,
    AllVisitorReq,
    LastOneWeekVisitorReq
   } = useDashboard()

   const { data:totalUser } = useQuery('total-student', totalStudentReq)
   const { data:totalSubject } = useQuery('total-subject', totalSubjectReq);
   const { data:totalQuestion } = useQuery('total-questions', totalQuestionsReq);
  //  const { data:totalBooks } = useQuery('total-books', totalBookssReq);
   const { data:totalExam } = useQuery('total-exam', totalExamsReq);
   const { data:totalExamInDraft } = useQuery('total-exam-in-draft', examInDraftReq);
  //  const { data:totalSubscriber } = useQuery('total-subscriber', subscriberCount);
   const { data:todaysVisitor } = useQuery('todays-visitor', toDaysVisitorReq);
   const { data:totalVisitor } = useQuery('todays-all', AllVisitorReq);
   const { data:weeklyVisitor } = useQuery('last-one-week-visitor', LastOneWeekVisitorReq);

    return (
      <Layouts title='assets' classname='dashboard'>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              className='sale'
              bodyStyle={{ padding: "20px" }}
            >
              <Stats icon={""} text='Todays Visitor' number={todaysVisitor} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              className='order'
              bodyStyle={{ padding: "20px" }}
            >
              <Stats icon={""} text='Weekly Visitor' number={weeklyVisitor} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              bodyStyle={{ padding: "20px" }}
              className='user'
            >
              <Stats icon={""} text='Total Visitor' number={totalVisitor} />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              className='sale'
              bodyStyle={{ padding: "20px" }}
            >
              <Stats icon={""} text='Total Subject' number={totalSubject} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              className='order'
              bodyStyle={{ padding: "20px" }}
            >
              <Stats icon={""} text='Total Student' number={totalUser} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              bodyStyle={{ padding: "20px" }}
              className='user'
            >
              <Stats icon={""} text='Total Question' number={totalQuestion} />
            </Card>
          </Col>
          {/* <Col xs={24} sm={12} md={8} lg={8} className="custom-statcards">
              <Card
                bordered={false}
                bodyStyle={{padding: '20px'}}
                className="visitor"
              >
                <Stats icon={''} text="Total Library Book" number={totalBooks} />
              </Card>
            </Col> */}
          <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              className='sale'
              bodyStyle={{ padding: "20px" }}
            >
              <Stats icon={""} text='Exam Taken' number={totalExam} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              className='order'
              bodyStyle={{ padding: "20px" }}
            >
              <Stats icon={""} text='Exam in Draft' number={totalExamInDraft} />
            </Card>
          </Col>
          {/* <Col xs={24} sm={12} md={8} lg={8} className='custom-statcards'>
            <Card
              bordered={false}
              className='order'
              bodyStyle={{ padding: "20px" }}
            >
              <Stats
                icon={""}
                text='Total Subscriber'
                number={totalSubscriber}
              />
            </Card>
          </Col> */}
        </Row>

        {/* <!--Stats view --> */}
      </Layouts>
    );
}

export default Dashboard;
