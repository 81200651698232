import React, { useState } from "react";
import Add from "./Add"
import List from "./List";
import Layouts from '../../components/Layouts';
const Index = () => {

const [view, setView] = useState("");
const [update, setUpdate] = useState(null);

  return <Layouts>
            {view==="" && <List update={update} setUpdate={setUpdate} setView={setView} />}
            {view==="add" && <Add update={update} setUpdate={setUpdate} setView={setView} />}
        </Layouts>;
};

export default Index;
