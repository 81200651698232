import React  from 'react';
import avatar from '../static/images/flat-avatar.png';
import { Link} from 'react-router-dom';

import { Menu, Input, Avatar} from 'antd';

const SubMenu = Menu.SubMenu;

const Search = Input.Search;

function  HeaderDiv() {


  function logoutHandler(){
    localStorage.removeItem('user');
    localStorage.removeItem('__token');
  }


    return (
      <Menu
        mode="horizontal"
        theme="dark"
        className="d-flex align-items-center custom-navigation"
      >

        <Menu.Item key="brand-logo" className="brand-logo">
          <Link to="/dashboard">
            {/* <img src={logo} className="m-r-5" alt='' /> */}
            <span>PreAdmissionBD</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="search" className="custom-search auto">
          {/* <Search onSearch={value => console.log (value)} /> */}
        </Menu.Item>

       
        <SubMenu
          key="profile"
          title={
            <span>
              <Avatar size={24} src={avatar} />
              <span> Profile</span>
            </span>
          }
          className=""
        >
          <Menu.Item key="profile-view">
            <Link to="/profile">Profile</Link>
          </Menu.Item>
          <Menu.Item onClick={logoutHandler} key="logout"><Link to="/">Logout</Link></Menu.Item>
        </SubMenu>

      </Menu>
    );
}
export default HeaderDiv;
