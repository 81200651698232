import { useAxios  } from "./../lib/AxiosProvider"
const useUpload = () => {
    const axios  = useAxios();

    async function addBookReq(payload){
        const { data } = await axios.post("/library/add-book", payload); 
        return data;
    }
    
    async function updateBookReq(payload){
        const { data } = await axios.post("/library/update-book", payload); 
        return data;
    }
    

    async function BookFetchRequest(payload){
        const { data  } = await axios.get(`/library/fetch-book`, {
            params:{
                ...payload
            }
        });
        return data;
    }


    async function BookDeleteReq({ id }){
        const { data  } = await axios.delete(`/library/book/${id}`);
        return data;
    };




    return {
        addBookReq,
        BookFetchRequest,
        BookDeleteReq,
        updateBookReq
    }
}

export default useUpload
