import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
function ProtectedRoute(props){
    const location = useLocation();
   
    const user = localStorage.getItem('user')
    let prop = { ...props, user }

    return user ? (
        <Route {...prop} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location }
          }}
        />
      );

}

export default ProtectedRoute;